const config = {
  apiGateway: {
    REGION: 'us-west-2',
    INTERNAL_API_URL: 'https://t1bcc5fhrb.execute-api.us-west-2.amazonaws.com/prod',
    TEAM_API_URL: 'https://vice72re3i.execute-api.us-west-2.amazonaws.com/api'
  },
  reactApp: {
    VERSION: 'taxonomy-teams-1.9.2',
    HOSTNAME: 'https://teams-service.saas.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.saas.forwoodsafety.com',
    WEBSOCKET: 'wss://k9ttmpq1n8.execute-api.us-west-2.amazonaws.com/prod'
  },
};

export default config;
